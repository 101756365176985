import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from '../src/components/layouts/DashboardLayout';
import LoadingScreen from '../src/components/postCard/LoadingScreen';
import AuthGuard from '../src/components/postCard/AuthGuard';
import GuestGuard from '../src/components/postCard/GuestGuard';
import SettingGuard from '../src/components/postCard/SettingGuard';
import TeamGuard from '../src/components/postCard/TeamGuard';
import AwardGuard from '../src/components/postCard/AwardGuard';
import AuthRoleGuard from './components/postCard/AuthRoleGuard';
import BoardingGuard from './components/postCard/BoardingGuard';
import GoalGuard from './components/postCard/GealGuard';
import ScreenGuard from './components/postCard/ScreenGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: AuthGuard,
    path: '/404',
    component: lazy(() => import('../src/components/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/billing/success',
    component: lazy(() => import('../src/components/billingAction/SuccessBilling'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/billing/failed',
    component: lazy(() => import('../src/components/billingAction/FailedBilling'))
  },
  {
    exact: true,
    guard: BoardingGuard,
    path: '/onBoarding',
    component: lazy(() => import('../src/components/ownerBoarding'))
  },
  {
    exact: true,
    guard: ScreenGuard,
    path: '/:type/upgrade',
    component: lazy(() => import('../src/components/Upgrade'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: () => <Redirect to="/signin" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signin',
    component: lazy(() => import('../src/components/signin/SignIn'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup/:plan',
    component: lazy(() => import('../src/components/signup/SignUp'))
  },
  {
    exact: true,
    path: '/plans',
    component: lazy(() => import('../src/components/visitplan/VisitPlan'))
  },
  {
    exact: true,
    path: '/verify-email',
    component: lazy(() => import('../src/components/email'))
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('../src/components/dashboard/DashboardView'))
      },
      {
        exact: true,
        path: '/dashboard/latest-activities',
        component: lazy(() => import('../src/components/latestactivity/LatestActivity'))
      },
      {
        exact: true,
        path: '/dashboard/latest-tasks',
        component: lazy(() => import('../src/components/latesttasks/LatestTasks'))
      },
      {
        exact: true,
        path: '/deals',
        component: lazy(() => import('../src/components/deal/DealsView'))
      },
      {
        exact: true,
        path: '/deals/:id',
        component: lazy(() => import('../src/components/deal/DealView'))
      },
      {
        exact: true,
        path: '/deals/new/open',
        component: lazy(() => import('../src/components/deal/AddDealView'))
      },
      {
        exact: true,
        path: '/deals/single/pipeline',
        component: lazy(() => import('../src/components/dealPipeline'))
      },
      {
        exact: true,
        path: '/cases',
        component: lazy(() => import('../src/components/case/CasesView'))
      },
      {
        exact: true,
        path: '/cases/:id',
        component: lazy(() => import('../src/components/case/CaseView'))
      },
      {
        exact: true,
        path: '/cases/new/open',
        component: lazy(() => import('../src/components/case/AddCase'))
      },
      {
        exact: true,
        path: '/cases/single/pipeline',
        component: lazy(() => import('../src/components/casePipeline'))
      },
      {
        exact: true,
        path: '/contacts',
        component: lazy(() => import('../src/components/contact/ContactsView'))
      },
      {
        exact: true,
        path: '/contacts/:id',
        component: lazy(() => import('../src/components/contact/ContactView'))
      },
      {
        exact: true,
        path: '/contacts/:id/deals/new/open',
        component: lazy(() => import('../src/components/deal/AddDealContact'))
      },
      {
        exact: true,
        path: '/contacts/:id/cases/new/open',
        component: lazy(() => import('../src/components/case/AddCaseContact'))
      },
      {
        exact: true,
        path: '/contacts/new/form',
        component: lazy(() => import('../src/components/contact/AddContact'))
      },
      {
        exact: true,
        path: '/contacts-import',
        component: lazy(() => import('../src/components/contact/ImportContactView'))
      },
      {
        exact: true,
        path: '/companies',
        component: lazy(() => import('../src/components/company/CompaniesView'))
      },
      {
        exact: true,
        path: '/companies/:id',
        component: lazy(() => import('../src/components/company/CompanyView'))
      },
      {
        exact: true,
        path: '/companies/:id/contact/new/form',
        component: lazy(() => import('../src/components/company/AddCompanyContact'))
      },
      {
        exact: true,
        path: '/companies/new/form',
        component: lazy(() => import('../src/components/company/AddCompany'))
      },
      {
        exact: true,
        path: '/products',
        component: lazy(() => import('../src/components/product/ProductsView'))
      },
      {
        exact: true,
        path: '/products/:id',
        component: lazy(() => import('../src/components/product/ProductView'))
      },
      {
        exact: true,
        path: '/products/new/form',
        component: lazy(() => import('../src/components/product/AddProduct'))
      },
      {
        exact: true,
        path: '/products-import',
        component: lazy(() => import('../src/components/product/ImportProduct'))
      },
      {
        exact: true,
        guard: TeamGuard,
        path: '/teams',
        component: lazy(() => import('../src/components/team/TeamsView'))
      },
      {
        exact: true,
        guard: GoalGuard,
        path: '/goals',
        component: lazy(() => import('../src/components/goal/GoalViews'))
      },
      {
        exact: true,
        guard: GoalGuard,
        path: '/goals/:id',
        component: lazy(() => import('../src/components/goal/GoalView'))
      },
      {
        exact: true,
        guard: GoalGuard,
        path: '/goals/new/form',
        component: lazy(() => import('../src/components/goal/AddGoal'))
      },
      {
        exact: true,
        guard: AwardGuard,
        path: '/awards',
        component: lazy(() => import('../src/components/award/AwardViews'))
      },
      {
        exact: true,
        guard: AwardGuard,
        path: '/awards/:id',
        component: lazy(() => import('../src/components/award/AwardView'))
      },
      {
        exact: true,
        guard: AwardGuard,
        path: '/awards/new/form',
        component: lazy(() => import('../src/components/award/AddAward'))
      },
      {
        exact: true,
        guard: AwardGuard,
        path: '/awards/:id/form/:type',
        component: lazy(() => import('../src/components/award/AddAwardee'))
      },
      {
        exact: true,
        path: '/my-work',
        component: lazy(() => import('../src/components/mywork/MyWork'))
      },
      {
        exact: true,
        path: '/owner-profile',
        component: lazy(() => import('../src/components/ownerProfile/OwnerProfile'))
      },
      {
        exact: true,
        path: '/user-profile/:id',
        component: lazy(() => import('../src/components/userprofile/UserProfile'))
      },
      {
        exact: true,
        path: '/change-password',
        component: lazy(() => import('../src/components/changepassword'))
      },
      {
        exact: true,
        guard: SettingGuard,
        path: '/settings',
        component: () => <Redirect to="/settings/billing" />
      },
      {
        exact: true,
        guard: AuthRoleGuard,
        path: '/settings/:threadKey',
        component: lazy(() => import('../src/components/settings/index'))
      },
      {
        component: () => <Redirect to="/onBoarding" />
      },
      {
        component: () => <Redirect to="/billing/success" />
      },
      {
        component: () => <Redirect to="/billing/failed" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PLANS } from '../common/Plans/Plans';
import { ROLES } from '../common/Role/UserRole';

const AwardGuard = ({ children }) => {
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    const user = JSON.parse(localStorage.getItem('userDetails'));

    if (subscription.planName === PLANS.FREE.name || subscription.planName === PLANS.STARTER.name && user.role === ROLES.OWNER) {
        return <Redirect to="/awards/upgrade" />;
    }
    else if (subscription.planName === PLANS.FREE.name || subscription.planName === PLANS.STARTER.name && user.role === ROLES.MANAGER) {
        return <Redirect to="/dashboard" />;
    }
    else if (user.role === ROLES.USER) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            {children}
        </>
    );
};

AwardGuard.propTypes = {
    children: PropTypes.node
};

export default AwardGuard;

import React, { useState } from "react";
import clsx from 'clsx';
import { withRouter } from "react-router";
import LogoBrand from "./LogoBrand";
import Account from "./Account";
import MenuIcon from '@material-ui/icons/Menu';
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  AppBar,
  Hidden,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import Themes from './Themes';
import Search from './Search';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  changeMode: {
    backgroundColor: theme.palette.background.paper,
  }
}));

const TopBar = ({ onNavOpen, isNavOpen }) => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(classes.appBar)}
    >
      <Paper className={classes.paperBorder}>
        <Toolbar className={classes.changeMode}>

          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onNavOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <LogoBrand />
          </Hidden>

          <Box
            ml={2}
            flexGrow={1}
          />
          <Search />
          <Themes />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </Paper>
    </AppBar>
  );
}

export default withRouter(TopBar);
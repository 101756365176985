import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { Providers } from "@microsoft/mgt-element";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SettingsProvider } from '../src/components/context/SettingsContext';

require('dotenv').config();
Providers.globalProvider = new Msal2Provider({
    clientId: '7afd376e-fb70-41ab-bfe8-b9413a9319a9',
    loginType: 0,
    scopes: ['contacts.read', 'contacts.read.shared', 'contacts.readWrite', 'offline_access',
        'email', 'openid', 'profile', 'user.read', 'user.ReadBasic.All'],
});

const defaultQueryOptions = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 30000,
        },
    },
}
const queryClient = new QueryClient(defaultQueryOptions);
ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <SettingsProvider>
                <App />
            </SettingsProvider>
            <ReactQueryDevtools />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root'));


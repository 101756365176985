import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from '../postCard/SplashScreen';

import { useQueryClient } from "react-query";

const initialAuthState = {
  isAuthenticated: false,
  isBoarding: false,
  isInitialised: false,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};


const setSession = (accessToken, subscription, orgDetails, userDetails) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('subscription', JSON.stringify(subscription));
    localStorage.setItem('organisation', JSON.stringify(orgDetails));
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('subscription');
    localStorage.removeItem('organisation');
    localStorage.removeItem('userDetails');
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, isBoarding } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isBoarding,
        isInitialised: true,
      };
    }
    case 'LOGIN': {
      const { isBoarding } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isBoarding
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        isBoarding: false
      };
    }
    case 'REGISTER': {

      return {
        ...state,
        isAuthenticated: false,
        isBoarding: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const queryClient = useQueryClient();

  const login = (token, subscription, organisation, userData) => {
    const userDetails = {
      name: userData.name,
      role: userData.role_name
    }
    const subsDetails = {
      planName: subscription.plan_name,
      status: subscription.status,
    }
    const orgDetails = {
      orgId: organisation.org_id,
      orgName: organisation.org_name,
      email: organisation.email,
      phone: organisation.phone,
      website: organisation.website,
      orgPic: organisation.org_pic_url,
      addressLine1: organisation.address_line1,
      addressLine2: organisation.address_line2,
      city: organisation.city,
      state: organisation.state,
      zipcode: organisation.zipcode,
      country: organisation.country
    }
    setSession(token, subsDetails, orgDetails, userDetails);
    let boardingValue = false;
    if (subsDetails.role === "Owner" && (orgDetails.name === null || orgDetails.email === null || orgDetails.phone === null)) {
      boardingValue = true;
    }
    console.log("Pass the value of the boarding to take action to login", boardingValue)
    dispatch({
      type: 'LOGIN',
      payload: {
        isBoarding: boardingValue
      }
    });
  };

  const logout = () => {
    setSession(null, null, null, null);
    queryClient.clear()
    dispatch({ type: 'LOGOUT' });
  };

  const register = () => {
    dispatch({
      type: 'REGISTER',
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const subscriptionData = JSON.parse(window.localStorage.getItem('subscription'));
        const orgDetails = JSON.parse(window.localStorage.getItem('organisation'));
        const userDetails = JSON.parse(window.localStorage.getItem('userDetails'));

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, subscriptionData, orgDetails, userDetails);

          let boardingValue = false;
          if (orgDetails.name === null || orgDetails.email === null || orgDetails.phone === null) {
            boardingValue = true;
          }

          console.log("Pass the value of the boarding to take action ", boardingValue)

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              isBoarding: boardingValue
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              isBoarding: false
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            isBoarding: false
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
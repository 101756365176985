import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PLANS } from '../common/Plans/Plans';

const SettingGuard = ({ children }) => {
    const subscription = JSON.parse(localStorage.getItem('subscription'));

    if (subscription.planName === PLANS.FREE.name) {
        return <Redirect to="/settings/billing" />;
    }

    return (
        <>
            {children}
        </>
    );
};

SettingGuard.propTypes = {
    children: PropTypes.node
};

export default SettingGuard;

import React, {
  useRef,
  useState
} from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
import {
  Grid,
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import Admin from '../../../assets/images/default.png'
import OwnerIcon from '../../../assets/images/owner.png'
import ManagerIcon from '../../../assets/images/manager.png'
import UserIcon from '../../../assets/images/user.jpg'
import { useOwnerProfile } from "../../../hooks/UseOwnerProfile";
import useAuthentication from '../../../hooks/useAuthentication';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 150,
  },
  meniItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.hover
    }
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    fontWeight: 'inherit',
    flexGrow: 1
  }
}));

const Account = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { logout } = useAuthentication();
  // const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const { data, status, error, isFetching, isPreviousData, refetch } = useOwnerProfile();

  let userDetails = {};
  if (status === "success") {
    console.log("Owner profile in the profile menu", data);
    userDetails = data;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  }

  const getIcon = () => {
    if (userDetails.role_name === "Owner") {
      return OwnerIcon;
    }
    else if (userDetails.role_name === "Manager") {
      return ManagerIcon;
    }
    else if (userDetails.role_name === "User") {
      return UserIcon;
    }
    else {
      return Admin;
    }
  }

  let ownerName = `${userDetails.name}`;
  ownerName = ownerName.substring(0, ownerName.indexOf(" "));

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={userDetails && userDetails.profile_pic_url ? userDetails.profile_pic_url : getIcon()}
        />
        <Hidden smDown>
          <Grid
            container
            direction="column">
            <Typography
              variant="h6"
              color="inherit"
            >
              {userDetails && ownerName ? ownerName : "User"}
            </Typography>
            <Typography variant="caption">
              {userDetails && userDetails.role_name}
            </Typography>
          </Grid>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          className={classes.meniItem}
          component={RouterLink}
          to="/owner-profile"
          onClick={handleClose}
        >
          <PersonIcon className={classes.icon} />
          <Typography
            className={classes.text}
            variant="body2"
          >
            Profile
          </Typography>
        </MenuItem>
        <MenuItem className={classes.meniItem} onClick={handleLogout}>
          <PowerSettingsNewIcon className={classes.icon} />
          <Typography
            className={classes.text}
            variant="body2"
          >
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default withRouter(Account);

import React from 'react';
import { withRouter } from 'react-router';
import {
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
}));

const NavBar = (props) => {

  const classes = useStyles();
  const { onNavClosed, isNavOpen } = props;

  return (
    <>
      <Hidden lgUp>
        <MobileNavBar onNavClosed={onNavClosed} isNavOpen={isNavOpen} />
      </Hidden>
      <Hidden mdDown>
        <DesktopNavBar onNavClosed={onNavClosed} isNavOpen={isNavOpen} />
      </Hidden>
    </>
  );
}

export default withRouter(NavBar);
import { useQuery } from "react-query";
import { axiosClient, headers } from "./ApiConfig";

export const useGolbalSearching = (type, searchString, page, pageSize) => {
    return useQuery(["globalSearching", type, searchString, page, pageSize], () => getSearchDetails(type, searchString, page, pageSize), { keepPreviousData: true });
}

/*
Fetch deals information from dealId.
 */
const getSearchDetails = async (type, searchString, page, pageSize) => {
    console.log("Pass the data to search deals is ", type, searchString, page, pageSize);
    const dealUrl = `/identity/v1/search/search/${type}/${searchString}?page=${page}&page_size=${pageSize}`;
    const response = await axiosClient.get(dealUrl, { headers: headers() });
    console.log('The response to get the search list is ', response.data);
    return response.data.data;
}
export const getNormalDate = (date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const fullDate = new Date(date);
    let year = fullDate.getYear() + 1900;
    let month = monthNames[fullDate.getMonth()];
    let dates = fullDate.getDate();

    return dates + " " + month + " " + year;
}

export const getDateWithDay = (date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const fullDate = new Date(date);

    const dateformate = dayNames[fullDate.getDay()] + ', ' + fullDate.getDate() + ' ' + monthNames[fullDate.getMonth()];

    let hours = fullDate.getHours();

    // Check whether AM or PM 
    const newFormat = hours >= 12 ? 'PM' : 'AM';

    // Find current hour in AM-PM Format 
    hours = hours % 12;

    // To display "0" as "12" 
    hours = hours ? hours : 12;

    let minutes = fullDate.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    // console.log("Day is ", day)

    const timeFormat = hours + ":" + minutes + ' ' + newFormat;

    return dateformate + " " + timeFormat;
}

export const getDueDate = (date) => {

    let currentDate = new Date();
    let originalDate = new Date(date);

    if (date === "" || originalDate < currentDate) return 0;

    const oneDay = 24 * 60 * 60 * 1000;
    // console.log("Day is ", diffDays)
    return Math.round(Math.abs((originalDate - currentDate) / oneDay));
}

export const getCurrentMonth = () => {
    let currentDate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    return monthNames[currentDate.getMonth()];
}
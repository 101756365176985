import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PLANS } from '../common/Plans/Plans';

const ScreenGuard = ({ children }) => {
    const subscription = JSON.parse(localStorage.getItem('subscription'));

    if (subscription.planName === PLANS.GROWTH.name) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            {children}
        </>
    );
};

ScreenGuard.propTypes = {
    children: PropTypes.node
};

export default ScreenGuard;

export const PLANS = {
    FREE: {
        planId: 3,
        name: 'Free',
        monthlyAmount: {
            inr: 0,
            usd: 0
        },
        annuallyAmount: {
            inr: 0,
            usd: 0
        },
        features: [
            "250 Contacts",
            "100 Companies",
            "10 Products",
            "5 Deals a month",
            "5 Cases a month"
        ],
        mt: 13
    },
    STARTER: {
        planId: 1,
        name: 'Starter',
        monthlyAmount: {
            inr: 250.00,
            usd: 7.00
        },
        annuallyAmount: {
            inr: 2000.00,
            usd: 60.00
        },
        features: [
            "Unlimited Contacts",
            "Unlimited Companies",
            "Unlimited Products",
            "Unlimited Deals",
            "Unlimited Cases"
        ],
        mt: 13
    },
    GROWTH: {
        planId: 2,
        name: 'Growth',
        monthlyAmount: {
            inr: 600.00,
            usd: 10.00
        },
        annuallyAmount: {
            inr: 6000.00,
            usd: 100.00
        },
        features: [
            "Everything in Starter",
            "Teams to organize sales teams",
            "Goals & Targets",
            "Motivational features",
            "Appreciation badges"
            , "Awards"
        ],
        mt: 10
    }
};
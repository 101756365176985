import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROLES } from '../common/Role/UserRole';

const AuthRoleGuard = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('userDetails'));

    if (user.role !== ROLES.OWNER) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            {children}
        </>
    );
};

AuthRoleGuard.propTypes = {
    children: PropTypes.node
};

export default AuthRoleGuard;

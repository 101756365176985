import React, { useState } from 'react';
import { withRouter } from 'react-router';
import Auth from '../../rbac/Auth';
import { PLANS } from '../../common/Plans/Plans';
import {
    Grid
} from "@material-ui/core";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItem from "@material-ui/core/ListItem";

import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ContactsIcon from '@material-ui/icons/Contacts';
import BusinessIcon from '@material-ui/icons/Business';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import GradeIcon from '@material-ui/icons/Grade';
import NatureIcon from '@material-ui/icons/Nature';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SettingsIcon from '@material-ui/icons/Settings';

import { useOwnerProfile } from "../../../hooks/UseOwnerProfile";
import clsx from 'clsx';

import { Mixpanel } from '../../common/Mixpanel';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    lineItem: { paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' },
    activeRoot: {
        "&$selected": {
            backgroundColor: theme.palette.background.selected,
        },
        "&$selected:hover": {
            backgroundColor: theme.palette.background.selected,
        },
        "&:hover": {
            backgroundColor: theme.palette.background.hover
        }
    },
    desktopDrawer: {
        width: 90,
        top: 57,
    },
    selected: {}
}));

const ListItem = withStyles((theme) => ({
    root: {
        "&$selected": {
            backgroundColor: theme.palette.background.selected,
        },
        "&$selected:hover": {
            backgroundColor: theme.palette.background.selected,
        },
        "&:hover": {
            backgroundColor: theme.palette.background.hover
        }
    },
    selected: {}
}))(MuiListItem);

const DesktopNavBar = (props) => {


    const { data, status, error, isFetching, isPreviousData, refetch } = useOwnerProfile();

    let userDetails = {};
    if (status === "success") {
        userDetails = data;
        console.log("Get the owner details is ", userDetails);
    }

    const classes = useStyles();
    const [run, setRun] = useState(true);
    const [stepIndex, setStepIndex] = useState(0);
    const [steps, setSteps] = useState([
        {
            content: <h2>Let's begin our journey!</h2>,
            locale: { skip: <strong aria-label="skip">SKIP</strong> },
            placement: 'center',
            target: 'body',
        },
        {
            content: (
                <Typography variant='h6' color='textSecondary'>
                    Import contacts and create a new contact first.
                </Typography>
            ),
            hideBackButton: true,
            locale: { skip: <strong aria-label="skip">SKIP</strong> },
            placement: 'bottom',
            styles: { options: { width: 400 } },
            target: '.contact_grid',
            title: <Typography variant='h4'>Contact</Typography>,
        },
        {
            content: (
                <Typography variant='h6' color='textSecondary'>
                    Import products and create a new products.
                </Typography>
            ),
            locale: { skip: <strong aria-label="skip">SKIP</strong> },
            placement: 'left',
            styles: { options: { width: 400 } },
            target: '.product_grid',
            title: <Typography variant='h4'>Product</Typography>,
        },
        {
            content: (
                <Typography variant='h6' color='textSecondary'>
                    Create a new issue more than two product for a particular contact.
                </Typography>
            ),
            locale: { skip: <strong aria-label="skip">SKIP</strong> },
            placement: 'top',
            styles: { options: { width: 400 } },
            target: '.deal_grid',
            title: <Typography variant='h4'>Deal</Typography>,
        },
    ]);

    const userData = JSON.parse(localStorage.getItem('userDetails'));
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    console.log("Get the details of the subscription in the nav bar is ", subscription, PLANS.FREE.name);

    let barActive = "";
    const redirectPage = (type) => {
        trackServices(type);
        props.history.push(`/${type}`);
    }

    const trackServices = (type) => {
        if (type === "deals") {
            Mixpanel.track(`Deal Tracking`,
                {
                    name: userData?.name,
                    role: userData?.role
                });
            Mixpanel.people.set({
                name: userData?.name,
                role: userData?.role
            });
        }
        if (type === "cases") {
            Mixpanel.track(`Case Tracking`,
                {
                    name: userData?.name,
                    role: userData?.role
                });
            Mixpanel.people.set({
                name: userData?.name,
                role: userData?.role
            });
        }
        if (type === "contacts") {
            Mixpanel.track(`Contact Tracking`,
                {
                    name: userData?.name,
                    role: userData?.role
                });
            Mixpanel.people.set({
                name: userData?.name,
                role: userData?.role
            });
        }
        if (type === "products") {
            Mixpanel.track(`Product Tracking`,
                {
                    name: userData?.name,
                    role: userData?.role
                });
            Mixpanel.people.set({
                name: userData?.name,
                role: userData?.role
            });
        }
    }

    var parentURL = window.location.pathname.substring(1);
    var childURL = parentURL.substring(0, parentURL.indexOf("/"));

    if (childURL !== "") {
        barActive = childURL;
    }
    else {
        barActive = parentURL;
    }

    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            setRun(false);
        }

        console.groupCollapsed(type);
        console.log(data); //eslint-disable-line no-console
        console.groupEnd();
    };

    return (
        <div>
            <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                // getHelpers={this.getHelpers}
                run={run}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                stepIndex={stepIndex}
                steps={steps}
                styles={{
                    options: {
                        primaryColor: '#229954',
                        zIndex: 10000,
                    },
                }}
            />
            <Drawer
                anchor="left"
                classes={{ paper: classes.desktopDrawer }}
                variant="permanent"
            >
                <List>
                    <ListItem
                        button
                        className={classes.lineItem}
                        selected={barActive === "dashboard" ? true : false}
                        onClick={() => redirectPage("dashboard")}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Dashboard</Typography>
                        </Grid>
                    </ListItem>

                    <ListItem
                        button
                        className={classes.lineItem}
                        selected={barActive === "deals" ? true : false}
                        onClick={() => redirectPage("deals")}
                    >
                        <Grid
                            className='deal_grid'
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Deals</Typography>
                        </Grid>
                    </ListItem>

                    <ListItem
                        button
                        className={classes.lineItem}
                        selected={barActive === "cases" ? true : false}
                        onClick={() => redirectPage("cases")}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <WorkOutlineIcon />
                            </ListItemIcon>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Cases</Typography>
                        </Grid>
                    </ListItem>

                    <ListItem
                        button
                        className={clsx(classes.lineItem)}
                        selected={barActive === "contacts" ? true : false}
                        onClick={() => redirectPage("contacts")}
                    >
                        <Grid
                            className='contact_grid'
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <ContactsIcon />
                            </ListItemIcon>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Contacts</Typography>
                        </Grid>
                    </ListItem>


                    <ListItem
                        button
                        className={classes.lineItem}
                        selected={barActive === "companies" ? true : false}
                        onClick={() => redirectPage("companies")}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <BusinessIcon />
                            </ListItemIcon>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Companies</Typography>
                        </Grid>
                    </ListItem>

                    <ListItem
                        button
                        className={classes.lineItem}
                        selected={barActive === "products" ? true : false}
                        onClick={() => redirectPage("products")}
                    >
                        <Grid
                            className='product_grid'
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <EmojiObjectsIcon />
                            </ListItemIcon>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Products</Typography>
                        </Grid>
                    </ListItem>

                    <Auth
                        role={userDetails && userDetails.role_name}
                        perform="team-page:visit"
                        yes={() => (
                            <ListItem
                                button
                                className={classes.lineItem}
                                selected={barActive === "teams" ? true : false}
                                onClick={() => redirectPage("teams")}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <ListItemIcon style={{ justifyContent: 'center' }}>
                                        <PeopleOutlineIcon />
                                    </ListItemIcon>
                                    <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Teams</Typography>
                                </Grid>
                            </ListItem>
                        )}
                    />
                    <Auth
                        role={userDetails && userDetails.role_name}
                        perform="goal:visit"
                        yes={() => (
                            <ListItem
                                button
                                className={classes.lineItem}
                                selected={barActive === "goals" ? true : false}
                                onClick={() => redirectPage("goals")}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <ListItemIcon style={{ justifyContent: 'center' }}>
                                        <GradeIcon />
                                    </ListItemIcon>
                                    <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Goals</Typography>
                                </Grid>
                            </ListItem>
                        )}
                    />

                    <Auth
                        role={userDetails && userDetails.role_name}
                        perform="award:visit"
                        yes={() => (
                            <ListItem
                                button
                                className={classes.lineItem}
                                selected={barActive === "awards" ? true : false}
                                onClick={() => redirectPage("awards")}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <ListItemIcon style={{ justifyContent: 'center' }}>
                                        <NatureIcon />
                                    </ListItemIcon>
                                    <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Awards</Typography>
                                </Grid>
                            </ListItem>
                        )}
                    />


                    <ListItem
                        button
                        className={classes.lineItem}
                        selected={barActive === "my-work" ? true : false}
                        onClick={() => redirectPage("my-work")}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ListItemIcon style={{ justifyContent: 'center' }}>
                                <AssignmentIndIcon />
                            </ListItemIcon>
                            <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">My Work</Typography>
                        </Grid>
                    </ListItem>
                    <Auth
                        role={userDetails && userDetails.role_name}
                        perform="setting-page:visit"
                        yes={() => (
                            <ListItem
                                button
                                className={classes.lineItem}
                                selected={barActive === "settings" ? true : false}
                                onClick={() => redirectPage("settings")}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <ListItemIcon style={{ justifyContent: 'center' }}>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <Typography style={{ fontWeight: 'bold' }} variant="body2" color="textSecondary">Settings</Typography>
                                </Grid>
                            </ListItem>
                        )}
                    />
                </List>
            </Drawer>
        </div>
    );
}

export default withRouter(DesktopNavBar);
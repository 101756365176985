import React from "react";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import companyName from "../../../assets/images/logo.svg";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    link: { textDecoration: 'none', color: '#196F3D' }
}));

const LogoBrand = () => {
    const classes = useStyles();
    return (
        <Link className={classes.link} to="/dashboard">
            <img src={companyName} width="250" height="60" alt="BusinessAware logo" />
        </Link>
    );
}

export default withRouter(LogoBrand);
import { useQuery } from "react-query";
import { axiosClient, headers } from "./ApiConfig";

export const useOwnerProfile = () => {
    return useQuery("ownerProfile", () => getOwnerProfile());
}

/*
    Fetch owner profile information.
*/
const getOwnerProfile = async () => {
    const profileUrl = `/identity/v1/user/user-profile`;
    const response = await axiosClient.get(profileUrl, { headers: headers() });
    console.log('The owner profile response is ', response);
    return response.data.data;
}
import React from 'react';
import { Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { jssPreset, ThemeProvider, StylesProvider } from "@material-ui/core";
import { createCrmTheme } from '../src/theme';
import useSettings from '../src/hooks/useSettings';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { AuthProvider } from '../src/components/context/JWTAuthContext';
import routes, { renderRoutes } from '../src/routes';
import GlobalStyles from '../src/components/postCard/GlobalStyles';
import ScrollReset from '../src/components/postCard/ScrollReset';

library.add(fab, faCheckSquare, faBriefcase);
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {

    const { settings } = useSettings();
    const theme = createCrmTheme({
        theme: settings.theme
    });

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router history={history}>
                        <AuthProvider>
                            <GlobalStyles />
                            <ScrollReset />
                            {renderRoutes(routes)}
                        </AuthProvider>
                    </Router>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}


export default App;

import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthentication from '../../hooks/useAuthentication';

const BoardingGuard = ({ children }) => {
  const { isAuthenticated, isBoarding } = useAuthentication();
  console.log("Get the value of the authenticatin in boarding guard to check ", isAuthenticated, isBoarding)
  if (!isAuthenticated) {
    return <Redirect to="/signin" />;
  }
  else if (isAuthenticated && !isBoarding) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

BoardingGuard.propTypes = {
  children: PropTypes.node
};

export default BoardingGuard;

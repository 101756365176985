import axios from "axios";
import baseURL from "../components/common/BaseUrl/BaseURL";


export const axiosClient = axios.create({
    baseURL: baseURL
})

export const headers = () => {
    const jwtToken = localStorage.getItem('accessToken');
    const headers = {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json"
    }
    return headers;
};

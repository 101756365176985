import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Grid, makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import NavBar from '../NavBar';
import TopBar from '../TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 10,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 85
    }
  },
  warningAlert: { border: '0.1em solid #CA6F1E' },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isNavOpen, setNavOpen] = useState(false);

  const vertical = 'top', horizontal = "center";
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    // console.log("get the net value when call a function ",navigator.onLine)
    setOpen(!navigator.onLine);
  };

  setInterval(() => {
    handleClick();
  }, 10000)

  return (
    <div className={classes.root}>
      <TopBar onNavOpen={() => setNavOpen(true)} isNavOpen={isNavOpen} />
      <NavBar onNavClosed={() => setNavOpen(false)} isNavOpen={isNavOpen} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
            <Snackbar style={{ paddingTop: '50px' }}
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              message="I love snacks"
              key={vertical + horizontal}>
              <Alert className={classes.warningAlert} severity="warning"><b>Not Connected</b> - Please check your connection!</Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;

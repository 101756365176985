import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        alignItems: 'center',
        borderRadius: 2,
        display: 'inline-flex',
        flexGrow: 0,
        whiteSpace: 'nowrap',
        cursor: 'default',
        flexShrink: 0,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
        height: 20,
        justifyContent: 'center',
        letterSpacing: 0.5,
        minWidth: 20,
        padding: theme.spacing(0.5, 1),
        textTransform: 'uppercase'
    },
    primary: {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.08)
    },
    secondary: {
        color: '#8E44AD',
        backgroundColor: fade('#8E44AD', 0.08)
    },
    error: {
        color: '#E74C3C',
        backgroundColor: fade('#E74C3C', 0.08)
    },
    success: {
        color: '#2ECC71',
        backgroundColor: fade('#2ECC71', 0.08)
    },
    warning: {
        color: '#F1C40F',
        backgroundColor: fade('#F1C40F', 0.08)
    },
    info: {
        color: '#3498DB',
        backgroundColor: fade('#3498DB', 0.08)
    },
    extra: {
        color: '#E719AB',
        backgroundColor: fade('#E719AB', 0.08)
    },
    other: {
        color: '#A569BD',
        backgroundColor: fade('#A569BD', 0.08)
    },
    default: {
        color: '#5D6D7E',
        backgroundColor: fade('#5D6D7E', 0.08)
    }
}));

const Label = ({
    className = '',
    color = 'secondary',
    children,
    style,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <span
            className={
                clsx(classes.root, {
                    [classes[color]]: color
                }, className)
            }
            {...rest}
        >
            {children}
        </span>
    );
};

Label.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info'])
};

export default Label;

import Label from "../../../common/Label/Label";
import React from "react";

const SearchLabel = (props) => {
    const actionMap = {
        'contact': {
            text: 'Contact',
            color: 'primary'
        },
        'company': {
            text: 'Company',
            color: 'secondary'
        },
        'case': {
            text: 'Case',
            color: 'error'
        },
        'deal': {
            text: 'Deal',
            color: 'warning'
        },
        'product': {
            text: 'product',
            color: 'other'
        },
        'task': {
            text: 'Task',
            color: 'info'
        },
        'note': {
            text: 'note',
            color: 'default'
        }
    };

    console.log("activity lable is ", props.action);
    const { text, color } = actionMap[props.action];

    return (
        <Label color={color}>
            {text}
        </Label>
    );
};

export default SearchLabel;
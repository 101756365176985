import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Drawer,
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  Search as SearchIcon,
} from 'react-feather';

import SearchList from './SearchList';
import SearchHeader from './SearchHeader';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 500,
    maxWidth: '100%',
    backgroundColor: theme.palette.background.default
  }
}));


const Search = () => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchString(null)
    setSearchType(null);
  };

  const handleStringValue = (type, string) => {
    console.log("Get the string to identitfy ", type, string);
    setSearchType(type);
    setSearchString(string);
  }


  return (
    <>
      <Tooltip title={<Typography variant='body1'>Search</Typography>}>
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <SearchHeader closedSearchDialog={handleClose} setStringValue={handleStringValue} />
            {
              (searchType && searchString) &&
              <SearchList closedSearchDialog={handleClose} searchType={searchType} searchString={searchString} />
            }
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Search;

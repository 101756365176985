import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import {
    Box,
    Card,
    makeStyles,
    CardHeader,
    Typography,
    TablePagination,
    CircularProgress
} from '@material-ui/core';
import Highlighter from "react-highlight-words";
import { getNormalDate } from '../../../Utility/GenerateDate';
import { useGolbalSearching } from "../../../../hooks/UseGlobalSearching";
import SearchLabel from './SearchLable';

const useStyles = makeStyles((theme) => ({
    labelPosition: { paddingTop: theme.spacing(2) },
    linkChanges: { fontSize: 20, textDecoration: 'none', color: '#3498DB' }
}));

const SearchList = (props) => {

    const classes = useStyles();
    const { closedSearchDialog, searchType, searchString } = props;
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const {
        data,
        status,
        error,
        isLoading,
        isFetching,
        isPreviousData
    } = useGolbalSearching(searchType, searchString, pageNumber, pageSize);

    let searchList = [];
    let totalElements = 0;
    if (status === 'success' && data !== undefined) {
        console.log("Get the global search string ", data, isPreviousData);
        searchList = data.search_list;
        totalElements = data.total_elements;
    }

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(event.target.value);
        setPageNumber(0);
    };

    return (
        <Box mt={4}>
            {isLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {
                        (status === 'success') &&
                        searchList.map((result, i) => (
                            <Box
                                key={i}
                                mb={1}
                            >
                                <Card>
                                    <CardHeader
                                        title={
                                            result.object_type.toLowerCase() === "task" || result.object_type.toLowerCase() === 'note' ?
                                                <HashLink
                                                    smooth
                                                    className={classes.linkChanges}
                                                    onClick={() => closedSearchDialog()}
                                                    to={`/${result?.parent_type.toLowerCase() + "s"}/${result.parent_id}#${result.object_type.toLowerCase() === "task" ? "task" + result.object_id : "note" + result.object_id}`}
                                                >
                                                    <Highlighter
                                                        highlightStyle={{ backgroundColor: 'yellow', padding: '0px', fontSize: '20px' }}
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[searchString]}
                                                        autoEscape={true}
                                                        textToHighlight={result.object_name}
                                                    />
                                                </HashLink>
                                                :
                                                <HashLink
                                                    smooth
                                                    className={classes.linkChanges}
                                                    onClick={() => closedSearchDialog()}
                                                    to={`/${result?.object_type.toLowerCase() + "s"}/${result.object_id}`}
                                                >
                                                    <Highlighter
                                                        highlightStyle={{ backgroundColor: 'yellow', padding: '0px', fontSize: '20px' }}
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[searchString]}
                                                        autoEscape={true}
                                                        textToHighlight={result.object_name}
                                                    />
                                                </HashLink>
                                        }
                                        subheader={
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {`created at ${getNormalDate(result.object_date)}`}
                                            </Typography>
                                        }
                                        action={
                                            <div className={classes.labelPosition}>
                                                <SearchLabel action={result.object_type.toLowerCase()} />
                                            </div>
                                        }
                                    />
                                </Card>
                            </Box>
                        ))}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={totalElements}
                        page={pageNumber}
                        onChangePage={handleChangePage}
                        rowsPerPage={pageSize}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Box>
    );
}

export default SearchList;
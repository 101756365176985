import React, { useState } from 'react';

import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@material-ui/core';
import {
    Search as SearchIcon,
    XCircle as XIcon
} from 'react-feather';

const SearchHeader = (props) => {

    const { closedSearchDialog, setStringValue } = props;
    const [searchString, setSearchString] = useState("");

    const handleClose = () => {
        closedSearchDialog();
    };

    const handleSearch = () => {
        console.log("Get the first charater of a string is ", searchString.charAt(0), typeof (searchString.charAt(0)))
        let type = null, string = null;
        if (searchString.charAt(0) === "#") {
            type = "tag";
            string = searchString.substring(1);
        }
        else {
            type = "string";
            string = searchString;
        }
        setStringValue(type, string)
    };

    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    variant="h4"
                    color="textPrimary"
                >
                    Search
                </Typography>
                <IconButton onClick={handleClose}>
                    <SvgIcon fontSize="small">
                        <XIcon />
                    </SvgIcon>
                </IconButton>
            </Box>
            <Box mt={2}>
                <TextField
                    fullWidth
                    autoFocus
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <SearchIcon />
                                </SvgIcon>
                            </InputAdornment>
                        )
                    }}
                    onChange={(event) => setSearchString(event.target.value)}
                    placeholder="Search records &amp; people"
                    value={searchString}
                    variant="outlined"
                />
            </Box>
            <Box
                mt={2}
                display="flex"
                justifyContent="flex-end"
            >
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSearch}
                >
                    Search
                </Button>
            </Box>
        </React.Fragment>
    );
}

export default SearchHeader;
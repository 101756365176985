import React from 'react';
import { withRouter } from 'react-router';
import Auth from '../../rbac/Auth';
import { PLANS } from '../../common/Plans/Plans';
import { makeStyles } from "@material-ui/core/styles";
// import theme from "../theme/Theme";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ContactsIcon from '@material-ui/icons/Contacts';
import BusinessIcon from '@material-ui/icons/Business';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import GradeIcon from '@material-ui/icons/Grade';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SettingsIcon from '@material-ui/icons/Settings';

import { useOwnerProfile } from "../../../hooks/UseOwnerProfile";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 200,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  lineItem: { paddingLeft: '15px', paddingTop: '5px', paddingBottom: '5px' },
  activeRoot: {
    '&$selected': {
      backgroundColor: theme.palette.background.selected,
      '&:hover': {
        backgroundColor: theme.palette.background.selected,
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.background.hover
    }
  },
  selected: {}
}));

const MobileNavBar = (props) => {

  const { data, status, error, isFetching, isPreviousData, refetch } = useOwnerProfile();

  let userDetails = {};
  if (status === "success") {
    userDetails = data;
    console.log("Get the owner details is ", userDetails);
  }

  const classes = useStyles();
  const { onNavClosed, isNavOpen } = props;

  const subscription = JSON.parse(localStorage.getItem('subscription'));
  console.log("Get the details of the subscription is ", subscription);

  const handleDrawerClose = () => {
    onNavClosed();
  }

  let barActive = "";
  const redirectPage = (type) => {
    onNavClosed();
    props.history.push(`/${type}`);
  }

  var parentURL = window.location.pathname.substring(1);
  var childURL = parentURL.substring(0, parentURL.indexOf("/"));

  if (childURL !== "") {
    barActive = childURL;
  }
  else {
    barActive = parentURL;
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.mobileDrawer }}
      onClose={handleDrawerClose}
      open={isNavOpen}
      variant="temporary"
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      <List>
        <ListItem id="dashboard" className={classes.lineItem}
          button onClick={() => redirectPage("dashboard")}
          classes={{ root: classes.activeRoot, selected: classes.selected }}
          selected={barActive === "dashboard" ? true : false}
        >
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>

        <ListItem id="deals" className={classes.lineItem}
          button onClick={() => redirectPage("deals")}
          classes={{ root: classes.activeRoot, selected: classes.selected }}
          selected={barActive === "deals" ? true : false}
        >
          <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
          <ListItemText primary={"Deals"} />
        </ListItem>

        <ListItem id="cases" className={classes.lineItem}
          button onClick={() => redirectPage("cases")}
          classes={{ root: classes.activeRoot, selected: classes.selected }}
          selected={barActive === "cases" ? true : false}
        >
          <ListItemIcon><WorkOutlineIcon /></ListItemIcon>
          <ListItemText primary={"Cases"} />
        </ListItem>

        <ListItem id="contacts" className={classes.lineItem}
          button onClick={() => redirectPage("contacts")}
          classes={{ root: classes.activeRoot, selected: classes.selected }}
          selected={barActive === "contacts" ? true : false}
        >
          <ListItemIcon><ContactsIcon /></ListItemIcon>
          <ListItemText primary={"Contacts"} />
        </ListItem>

        <ListItem id="companies" className={classes.lineItem}
          button onClick={() => redirectPage("companies")}
          classes={{ root: classes.activeRoot, selected: classes.selected }}
          selected={barActive === "companies" ? true : false}
        >
          <ListItemIcon><BusinessIcon /></ListItemIcon>
          <ListItemText primary={"Companies"} />
        </ListItem>

        <ListItem id="products" className={classes.lineItem}
          button onClick={() => redirectPage("products")}
          classes={{ root: classes.activeRoot, selected: classes.selected }}
          selected={barActive === "products" ? true : false}
        >
          <ListItemIcon><EmojiObjectsIcon /></ListItemIcon>
          <ListItemText primary={"Products"} />
        </ListItem>

        {
          subscription &&
          subscription.planName === PLANS.GROWTH.name &&
          <Auth
            role={userDetails && userDetails.role_name}
            perform="team-page:visit"
            yes={() => (
              <ListItem id="teams" className={classes.lineItem}
                button onClick={() => redirectPage("teams")}
                classes={{ root: classes.activeRoot, selected: classes.selected }}
                selected={barActive === "teams" ? true : false}
              >
                <ListItemIcon><PeopleOutlineIcon /></ListItemIcon>
                <ListItemText primary={"Teams"} />
              </ListItem>
            )}
          />
        }

        {
          subscription &&
          subscription.planName === PLANS.GROWTH.name &&
          <Auth
            role={userDetails && userDetails.role_name}
            perform="goal:visit"
            yes={() => (
              <ListItem id="goals" className={classes.lineItem}
                button onClick={() => redirectPage("goals")}
                classes={{ root: classes.activeRoot, selected: classes.selected }}
                selected={barActive === "goals" ? true : false}
              >
                <ListItemIcon><GradeIcon /></ListItemIcon>
                <ListItemText primary={"Goals"} />
              </ListItem>
            )}
          />
        }

        <ListItem id="my-work" className={classes.lineItem}
          button onClick={() => redirectPage("my-work")}
          classes={{ root: classes.activeRoot, selected: classes.selected }}
          selected={barActive === "my-work" ? true : false}
        >
          <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
          <ListItemText primary={"My Works"} />
        </ListItem>

        <Auth
          role={userDetails && userDetails.role_name}
          perform="setting-page:visit"
          yes={() => (
            <ListItem id="settings" className={classes.lineItem}
              button onClick={() => redirectPage("settings")}
              classes={{ root: classes.activeRoot, selected: classes.selected }}
              selected={barActive === "settings" ? true : false}
            >
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItem>
          )}
        />
      </List>
    </Drawer>
  );
}

export default withRouter(MobileNavBar);
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthentication from '../../hooks/useAuthentication';

const GuestGuard = ({ children }) => {
  const { isAuthenticated, isBoarding } = useAuthentication();

  console.log("is authneticated", isAuthenticated)
  if (isAuthenticated) {
    if(isBoarding){
      return <Redirect to="/onBoarding" />;
    }
    else{
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;

import React, {
  useRef
} from 'react';
import {
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles,
  Typography
} from '@material-ui/core';
import useSettings from '../../../hooks/useSettings';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const useStyles = makeStyles((theme) => ({
}));

const Themes = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();

  const handleLightTheme = () => {
    const themeValue = { theme: "LIGHT" }
    saveSettings(themeValue);
  };

  const handleDarkTheme = () => {
    const themeValue = { theme: "ONE_DARK" }
    saveSettings(themeValue);
  };


  return (
    <React.Fragment>
      {
        settings.theme === "ONE_DARK" ?
          <Tooltip title={<Typography variant='body1'>Light Theme</Typography>}>
            <IconButton
              color="inherit"
              onClick={handleLightTheme}
              ref={ref}
            >
              <SvgIcon fontSize="small">
                <Brightness7Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          :
          <Tooltip title={<Typography variant='body1'>Dark Theme</Typography>}>
            <IconButton
              color="inherit"
              onClick={handleDarkTheme}
              ref={ref}
            >
              <SvgIcon fontSize="small">
                <Brightness4Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
      }
    </React.Fragment>
  );
}

export default Themes;
